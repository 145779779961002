<template>
  <div>
    <div class="tile">
      <logsTable type="login" :columns="columns"></logsTable>
    </div>
  </div>
</template>
<script>
import logsTable from "../components/logs";
export default {
  data() {
    return {
      columns: [
        {
          label: "Email",
          field: this.splitEmail,
        },
        {
          label: "User",
          field: ({ request }) => {
            return request.user ? request.user : "-----";
          },
        },
        {
          label: "Method",
          field: "request.method",
        },
        {
          label: "License",
          field: ({ request }) => {
            return request.license.toUpperCase();
          },
        },
        {
          label: "Response",
          field: "response.info.message",
        },
        {
          label: "Date",
          field: "created",
          formatFn: (created) => {
            return new Date(created).toLocaleString("en-GB", {
              timeZone: "UTC",
            });
          },
        },
      ],
    };
  },
  components: {
    logsTable,
  },
  methods: {
    splitEmail({
      log: {
        response: { data },
        request: { email },
      },
    }) {
      if (this.status === true) {
        if (data[0] != undefined) {
          let cut1 = data[0].url.split("Email=");
          let cut2 = cut1[1].split("&tkn=");
          let email = cut2[0];
          return email;
        } else {
          return "-----";
        }
      } else {
        return email;
      }
    },
  },
};
</script>
